import { useMemo, useState } from 'react';
import { type ExampleType, ClientMethod, ContentType } from '@/lib/utils/curl/use-curl';
import { outputText } from '@/lib/mocks/api-data';
import { useHighlight } from '../use-highlight';
import { ApiList, validateJsonString } from '../api-doc-utils';
import { APICodeBlock } from '../api-docs/api-code-block';

// allow default params
export const APIDocViewer = ({
  defaultParams,
  defaultUrl,
  defaultExampleType,
  contentTypeEnabled,
  defaultContentType,
  defaultCode,
  displayClients,
  hideStreamToggle,
  block,
  clientMethod,
  hideLanguageToggle,
}: {
  defaultParams?: any;
  defaultUrl?: string;
  defaultExampleType?: ExampleType;
  contentTypeEnabled?: boolean;
  defaultContentType?: ContentType;
  defaultCode?: string;
  displayClients?: boolean;
  hideStreamToggle?: boolean;
  block?: boolean;
  clientMethod?: ClientMethod;
  hideLanguageToggle?: boolean;
}) => {
  const [extraParams, setExtraParams] = useState<Record<string, any>>(
    defaultParams ?? { limit: 50 }
  );
  const [websiteUrl, _setWebsiteUrl] = useState<string>(defaultUrl ?? 'https://spider.cloud');
  const [exampleType, setExampleType] = useState<ExampleType>(defaultExampleType ?? 'basic');
  const [contentType, setContentType] = useState<ContentType>(
    defaultContentType ?? ContentType.JSON
  );
  const [querySelector, _setQuerySelector] = useState<string>();
  const { highlighter, defaultColor } = useHighlight();

  const _budget = extraParams?.budget;
  const _querySelector = querySelector || extraParams?.querySelector;

  const isValidBudget = useMemo(() => _budget && validateJsonString(_budget), [_budget]);

  const dataObject = useMemo(() => {
    return {
      url: websiteUrl,
      root_selector: _querySelector || undefined,
      budget: isValidBudget
        ? typeof _budget === 'string'
          ? _budget
          : JSON.stringify(_budget)
        : undefined,
    };
  }, [websiteUrl, querySelector, _budget, _querySelector, isValidBudget]);

  const onContentTypeChange = (content: string) =>
    setContentType(ContentType[content as keyof typeof ContentType]);

  const curlProperties = useMemo(
    () => ({
      accessToken: 'Bearer $SPIDER_API_KEY',
      dataObject,
      extraParams,
      exampleType,
      contentType,
    }),
    [dataObject, extraParams, exampleType, contentType]
  );

  const onToggleStreamingExample = () => {
    setExampleType('streaming');
    setExtraParams((v) => ({
      ...v,
      limit: v.limit > 1 ? v.limit : 50,
    }));
  };

  const onToggleBasicExampleEvent = () => {
    setExampleType('basic');
    setExtraParams((v) => ({
      ...v,
      limit: v.limit > 1 ? v.limit : 1,
    }));
  };

  // dark:scrollbar dark:scrollbar-thumb-gray-800 dark:scrollbar-track-gray-950

  return (
    <APICodeBlock
      editorClassName={defaultContentType ? '' : `max-h-[21.5rem] max-w-[37.2rem]`}
      hideResponse
      defaultColor={defaultColor}
      curlProps={curlProperties}
      onToggleBasicExampleEvent={onToggleBasicExampleEvent}
      onToggleStreamingExample={onToggleStreamingExample}
      onContentTypeChange={onContentTypeChange}
      highlighter={highlighter}
      outputText={outputText}
      exampleType={exampleType}
      visibleApi={ApiList.Crawl}
      contentTypeEnabled={contentTypeEnabled}
      defaultContentType={defaultContentType}
      defaultCode={defaultCode}
      displayClients={displayClients}
      hideStreamToggle={hideStreamToggle}
      block={block}
      clientMethod={clientMethod}
      hideLanguageToggle={hideLanguageToggle}
    />
  );
};
